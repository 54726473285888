const DismissableToastComponent = ({ message, onClose }) => {

  return (
    <div className="DismissableToastComponent">
      <div className="message-wrapper">
        {message}
      </div>
      <div className="button-wrapper" onClick={onClose}>
        <span>Dispensar</span>
      </div>
    </div>
  );
}

export default DismissableToastComponent;