import BaseModel from "./BaseModel";

class ContainerMeasureModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data.name;
    this.height = data.height;
    this.width = data.width;
    this.depth = data.depth;
    this.dimensionText = (<>{this.name}<br /> {data.height} x {data.width} x {data.depth}</>)
  }

  getDimensionsString() {
    return `${this.height} x ${this.width} x ${this.depth} CM`;
  }
}

export default ContainerMeasureModel;
