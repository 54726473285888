import axios from "axios";
import axiosRetry from "axios-retry";

import StorageUtil, { KEYS } from "../helpers/utils/StorageUtil";
import config from "../config";
import { axiosRetryConfig } from "./GenericAPI";

export default class BaseSeparatorAPI {
  /**Métodos get */
  static async get(endpoint, data = {}) {
    const requestConfig = { params: data };
    return await api.get(endpoint, requestConfig);
  }

  /**Métodos post */
  static async post(endpoint, data = {}) {
    return await api.post(endpoint, data);
  }

  /**Método Put */
  static async put(endpoint, data = {}) {
    return await api.put(endpoint, data);
  }

  /**Método Patch */
  static async patch(endpoint, data = {}) {
    return await api.patch(endpoint, data);
  }

  /**Método delete */
  static async delete(endpoint, data = {}) {
    return await api.delete(endpoint, { data });
  }
}

const api = axios.create({
  baseURL: config.url,
});

api.interceptors.request.use(
  (config) => {
    let token = StorageUtil.getItem(KEYS.SEPARATOR_TOKEN);

    if (!token) token = StorageUtil.getItem(KEYS.LOCKER_TOKEN);

    console.log('base separator api ', config.data)

    config.headers = {
      // "Content-Type": "application/json; charset=utf-8",
      "Content-Type": config.data instanceof FormData ? "multipart/form-data; charset=utf-8" : "application/json; charset=utf-8",
      accept: "*/*",
    };
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosRetry(api, axiosRetryConfig);

