import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import webSocketStore from '../../stores/WebSocketStore';
import { createWebSocketUrl } from '../../utils/webSocketHelper';
import StorageUtil, { KEYS } from '../../helpers/utils/StorageUtil';

const LockerHealthCheckSocket = () => {

    const lockerUuid = StorageUtil.getItem(KEYS.LOCKER_UUID)
    const token = StorageUtil.getItem(KEYS.LOCKER_TOKEN)

    let healthCheckIntervalId;

    useEffect(() => {
        const url = createWebSocketUrl(`/ws/locker/health-check/${lockerUuid}/`, { token });

        const sendHealthCheck = (socket) => {
            socket.send(JSON.stringify({ message: `${lockerUuid} - Health-check` }));
        };

        const onConnect = (socket) => {
            healthCheckIntervalId = setInterval(() => sendHealthCheck(socket), 5000);
            return () => clearInterval(intervalId);
        };

        const onMessage = (data) => {
            console.log('message ', data)
        }

        const onClose = (event) => {
            console.error('WebSocket closed unexpectedly');
            if (healthCheckIntervalId) {
                clearInterval(healthCheckIntervalId);
            }
        }

        webSocketStore.connect(url,
            onConnect,
            onMessage,
            onClose
        );

        return () => {
            if (healthCheckIntervalId) {
                clearInterval(healthCheckIntervalId);
            }
            webSocketStore.disconnect(url);
        };
    }, [lockerUuid, token]);

    return null;
};

export default observer(LockerHealthCheckSocket);
