import { extendObservable, runInAction } from "mobx";

import UserAPI from "../services/UserAPI";
import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";

import UserModel from "../models/UserModel";
import SeparatorModel from "../models/SeparatorModel";

import StorageUtil, { KEYS } from "../helpers/utils/StorageUtil";

const initValues = {
  customer: undefined,
  separator: undefined,
  loading: false,
};

class UserStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.customer = undefined;
    this.loading = false;
    this.clearSeparatorKeys();
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  emptyUser() {
    this.customer = new UserModel({});
  }

  /**
   * Busca todas as informações de um customer
   * @param  {string} uuid
   */
  async getCustomer(uuid) {
    this.loading = true;

    if (!uuid) uuid = StorageUtil.getItem(KEYS.USER_KEY);

    const response = await UserAPI.getCustomer(uuid);
    this.loading = false;
    if (!response.error) {
      this.customer = new UserModel(response);
      return this.customer;
    };
  }

  setSeparatorKeys(uuid, token) {
    StorageUtil.setItem(KEYS.SEPARATOR_UUID, uuid);
    StorageUtil.setItem(KEYS.SEPARATOR_TOKEN, token);
  }

  clearSeparatorKeys() {
    StorageUtil.remove(KEYS.SEPARATOR_UUID);
    StorageUtil.remove(KEYS.SEPARATOR_TOKEN);
  }

  /**
   * Busca todas as informações de separador
   * @param  {string} uuid
   */
  async getSeparator(uuid) {
    this.loading = true;

    if (!uuid) uuid = StorageUtil.getItem(KEYS.SEPARATOR_UUID);

    try {
      const response = await UserAPI.getSeparator(uuid);
      this.loading = false;
      if (!response.error) {
        this.separator = new SeparatorModel(response);
        return this.separator;
      } else {
        this.clearSeparatorKeys();
      }
      return response;
    } catch (error) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        "Erro ao configurar dispositivo"
      );
      this.clearSeparatorKeys();
    }
  }
}


export default UserStore;
