import BaseModel from "./BaseModel";
import ContainerModel from "./ContainerModel";

class ContainerAllocationModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.inLocker = data?.inLocker;

    this.container = new ContainerModel(data?.container);
  }
}

export default ContainerAllocationModel;
