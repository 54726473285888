import BaseModel from "./BaseModel";
import PageModel from "./PageModel";

class FlowPageModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.page = new PageModel(data?.page);
    this.ordering = data?.ordering;
  }
}

export default FlowPageModel;
