import BaseAPI from "./BaseAPI";
import { URLS } from "./Endpoints";

class CompanyAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que busca features de empresa
   */
  static async getFeatures(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.COMPANY}/features`);
      if (response.status === 200) return response.data;
      return { error: "Erro ao buscar configurações de empresa." };
    } catch (e) {
      return this._exception(e, "Falha ao buscar configurações de empresa");
    }
  }
}

export default CompanyAPI;
