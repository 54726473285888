import axios from "axios";
import { pad } from "../utils/methods";
import config from "../config";
import md5 from "md5";



// Function to format the JSON string to key-value pairs
function formatJsonToKeyValue(json) {
  const jsonStr = JSON.stringify(json)

  // Remove the curly braces at the beginning and end of the string
  let trimmedStr = jsonStr.replace(/^{|}$/g, '');

  // Replace commas with line breaks
  let lineBreakStr = trimmedStr.replace(/,\s*/g, '\n');

  // Replace colons with a colon followed by a space for better readability
  let formattedStr = lineBreakStr.replace(/:\s*/g, ': ');

  return formattedStr;
}
const BASE_URL = config.urllocker;

export default class LocalAPI {
  //BASE/locker/check/01/01/hash
  static async checkOpenDoor(id, serialPort) {
    const result = await axios.get(LocalAPI.mountURL("check", id), {
      params: { serial_port: serialPort },
    });

    return { result: LocalAPI.isOpen(result.data), resultData: formatJsonToKeyValue(result.data) };
  }

  //BASE/locker/open/01/01/hash
  static async openDoorCommand(id, serialPort) {
    const result = await axios.get(LocalAPI.mountURL("open", id), {
      params: { serial_port: serialPort },
    });

    return { result: LocalAPI.isOpen(result.data), resultData: formatJsonToKeyValue(result.data) };
  }

  //BASE/locker/command/01/hash
  static async runLockerCommand(data, serialPort) {
    const result = await axios.post(LocalAPI.mountURL("command"), data, {
      params: { serial_port: serialPort },
    });
    return result.data["success"];
  }

  //return true to open and false to close.
  static isOpen(data) {
    const { status } = Array.isArray(data) ? data[0] : data;
    return status.slice(-1) === "O"; //or C de Close
  }

  //create hash to get value.
  static get hash() {
    const key = "123456";
    const word = "LOCKERYS";
    return md5(`${key}${word}`).toLowerCase();
  }

  // mount url.
  static mountURL(action, id) {
    /* if(BASE_URL === url_locker_mock) {
            return `${BASE_URL}/${action}`
        }else{ */
    return `${BASE_URL}/locker/${action}/01${id ? "/" + pad(id, 2) : ""}/${LocalAPI.hash
      }`;
    //}
  }
}
