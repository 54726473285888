import BaseModel from "./BaseModel";
import FlowPageModel from "./FlowPageModel";

class FlowModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data?.name;

    this.flowpages =
      data?.flowpages.length > 0
        ? data?.flowpages.map((i) => new FlowPageModel(i))
        : [];
  }
}

export default FlowModel;
