import BaseModel from "./BaseModel";

class LockerFeaturesModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.preRegisteredAllocateFlow = data?.preRegisteredAllocateFlow;
    this.allocateFlow = data?.allocateFlow;
    this.deallocateFlow = data?.deallocateFlow;
    this.reverseFlow = data?.reverseFlow;
  }
}

export default LockerFeaturesModel;
