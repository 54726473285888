import toast from "react-hot-toast";
import DismissableToastComponent from "../components/DismissableToastComponent/DismissableToastComponent";

export default class ToastHelper {
  notify = (
    type = "success",
    message = "NotificationToaster",
    duration = 5000,
    position = "top-center",
    toastId
  ) => {
    switch (type) {
      case STATUS_HELPER.SUCCESS:
        toast.success(message, {
          position: position,
          options: {
            duration,
          }
        });
        break;
      case STATUS_HELPER.ERROR:
        toast.error(message, {
          position: position,
          duration: duration,
          toastId: "errorToaster",

        });
        break;
      case STATUS_HELPER.DISMISSABLE:
        toast((t) => (
          <DismissableToastComponent
            message={message}
            onClose={() => toast.dismiss(t.id)}
          />
        ), { duration });
        break;
      default:
        toast.success(message, {
          position: position,
          duration: duration,
        });
    }
  };
}

export const STATUS_HELPER = Object.freeze({
  ERROR: "error",
  INFO: "info",
  WARNING: "warning",
  SUCCESS: "success",
  DISMISSABLE: "dismissable"
});
