import BaseAPI from "./BaseAPI";
import BaseSeparatorAPI from "./BaseSeparatorAPI";
import { GenericAPI } from "./GenericAPI"

import { URLS } from "./Endpoints";

class AllocationAPI extends GenericAPI {
  /**
   * Request que retorna encomenda a partir de code e uuid de locker
   * @param  {string} data - objeto de allocation
   */
  static async getAllocationByUuid(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/${uuid}`);
      if (response.status === 200) return response.data;
      return this._exception(response, "Encomenda não encontrada.");
    } catch (e) {
      return this._exception(e, "Encomenda não encontrada.");
    }
  }

  /**
   * Request que retorna encomenda a partir de code e uuid de locker
   * @param  {string} data - objeto de allocation
   */
  static async getAllocationByCodeAndLockerUuid(code, lockerUuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ALLOCATION}/by-code/${code}`, {
        locker: lockerUuid,
      });
      if (response.status === 200) return response.data;
      return this._exception(response, "Encomenda não encontrada.");
    } catch (e) {
      return this._exception(e, "Encomenda não encontrada.");
    }
  }

  /**
   * Request que retorna encomenda a partir de code e uuid de locker
   * @param  {string} data - objeto de allocation
   */
  static async getAllocationByExternalCodeAndLockerUuid(code, lockerUuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ALLOCATION}/by-external-code/${code}`,
        {
          locker: lockerUuid,
        }
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Encomenda não encontrada.");
    } catch (e) {
      return this._exception(e, "Encomenda não encontrada.");
    }
  }

  /**
   * Request que retorna alocaçoes em regra de reversa ou cancelados
   * @param  {string} uuid - uuid de locker
   */
  static async getLockerReverseList(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ALLOCATION}/locker/${uuid}/reverse-list`
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Locker sem reversas");
    } catch (e) {
      return this._exception(e, "Locker sem reversas");
    }
  }

  /**
   * Request que registra encomendaf
   * @param  {string} data - objeto de allocation
   */
  static async create(data) {
    try {
      const response = await BaseSeparatorAPI.post(`${URLS.ALLOCATION}/`, data);
      if (response.status === 201) return response.data;
      return this._exception(response, "Erro ao registrar encomenda.");
    } catch (e) {
      return this._exception(e, "Falha ao registrar encomenda.");
    }
  }

  /**
   * Request que registra encomenda
   * @param  {string} data - objeto de allocation
   */
  static async allocate(uuid, data) {
    try {
      const response = await BaseSeparatorAPI.put(
        `${URLS.ALLOCATION}/${uuid}/allocate`,
        data
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao registrar encomenda.");
    } catch (e) {
      return this._exception(e, "Falha ao registrar encomenda.");
    }
  }

  /**
   * Request que registra encomenda
   * @param  {string} data - objeto de allocation
   */
  static async allocationFinished(uuid) {
    try {
      const response = await BaseSeparatorAPI.put(
        `${URLS.ALLOCATION}/${uuid}/allocation-finished`
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao registrar conclusao de entrega de pedido.");
    } catch (e) {
      return this._exception(
        e,
        "Falha ao registrar conclusao de entrega de pedido."
      );
    }
  }

  /**
   * Request que registra encomenda
   * @param  {string} data - objeto de allocation
   */
  static async deallocate(uuid, data) {
    try {
      const response = await BaseAPI.put(
        `${URLS.ALLOCATION}/${uuid}/deallocate`,
        data
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao registrar retirada.");
    } catch (e) {
      return this._exception(e, "Falha ao registrar retirada.");
    }
  }

  /**
   * Request que registra encomenda
   * @param  {string} data - objeto de allocation
   */
  static async reverse(uuid, data) {
    try {
      const response = await BaseSeparatorAPI.put(
        `${URLS.ALLOCATION}/${uuid}/reverse`,
        data
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao registrar reversa.");
    } catch (e) {
      return this._exception(e, "Falha ao registrar reversa.");
    }
  }

  static async allocationPhoto(uuid, data) {
    try {
      const response = await BaseSeparatorAPI.patch(
        `${URLS.ALLOCATION}/allocation-container/${uuid}/photo`,
        data
      );
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao registrar foto de encomenda.");
    } catch (e) {
      return this._exception(e, "Falha ao registrar foto de encomenda.");
    }
  }
}

export default AllocationAPI;
