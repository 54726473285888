import { extendObservable } from "mobx";

import LockerAPI from "../services/LockerAPI";
import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";

import LockerModel from "../models/LockerModel";
import LockerFeaturesModel from "../models/LockerFeaturesModel";

import StorageUtil, { KEYS } from "../helpers/utils/StorageUtil";
import ContainerTypeModel from "../models/ContainerTypeModel";
import ContainerMeasureModel from "../models/ContainerMeasureModel";
import ContainerModel from "../models/ContainerModel";

const initValues = {
  locker: undefined,
  features: undefined,
  availableContainersTypes: [],
  availableContainersMeasures: [],
  availableContainers: [],
  loading: false,
};

class LockerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.locker = undefined;
    this.features = undefined;
    this.loading = false;

    this.availableContainersTypes = [];
    this.availableContainersMeasures = [];
    this.availableContainers = [];
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  emptyLocker() {
    this.locker = new LockerModel({});
    this.features = new LockerFeaturesModel({});
  }

  /**
   * Busca todas as informações de um locker
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;

    const response = await LockerAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.locker = new LockerModel(response);
      return this.locker;
    } else if (!response.isNetworkError) {
      this.clearLockerKeys();
    }
    return response;
  }

  setLockerKeys(uuid, token) {
    StorageUtil.setItem(KEYS.LOCKER_UUID, uuid);
    StorageUtil.setItem(KEYS.LOCKER_TOKEN, token);
  }

  clearLockerKeys() {
    console.log('limpando chaves')
    StorageUtil.remove(KEYS.LOCKER_UUID);
    StorageUtil.remove(KEYS.LOCKER_TOKEN);
  }

  /**
   * Busca todas as informações de features de locker
   * @param  {string} uuid
   */
  async getFeatures(uuid) {
    this.loading = true;
    if (!uuid) uuid = StorageUtil.getItem(KEYS.LOCKER_UUID);

    const response = await LockerAPI.getFeatures(uuid);
    this.loading = false;
    if (!response.error) {
      this.features = new LockerFeaturesModel(response);
      return this.features;
    } else if (!response.isNetworkError) {
      this.clearLockerKeys();
    }
    return response;
  }

  /**
   * Busca todas as informações de features de locker
   
   */
  async getAvailableContainerTypes() {
    this.loading = true;
    const uuid = StorageUtil.getItem(KEYS.LOCKER_UUID);

    const response = await LockerAPI.getAvailableContainerTypes(uuid);
    this.loading = false;
    if (!response.error) {
      this.availableContainersTypes = response.content.map(
        (i) => new ContainerTypeModel(i)
      );
      return this.availableContainersTypes;
    }
  }

  /**
   * Busca todas as informações de features de locker
   * @param  {string} uuidContainerType
   */
  async getAvailableContainerMeasures(uuidContainerType) {
    this.loading = true;
    const uuid = StorageUtil.getItem(KEYS.LOCKER_UUID);

    const response = await LockerAPI.getAvailableContainerMeasures(
      uuid,
      uuidContainerType
    );
    this.loading = false;
    if (!response.error) {
      this.availableContainersMeasures = response.content.map(
        (i) => new ContainerMeasureModel(i)
      );
      return this.availableContainersMeasures;
    }
  }

  /**
   * Busca todas as informações de features de locker
   * @param  {string} uuidContainerType
   * @param  {string} uuidContainerMeasure
   */
  async getAvailableContainersByTypeAndMeasure(
    uuidContainerType,
    uuidContainerMeasure
  ) {
    this.loading = true;
    const uuid = StorageUtil.getItem(KEYS.LOCKER_UUID);

    const response = await LockerAPI.getAvailableContainersByTypeAndMeasure(
      uuid,
      uuidContainerType,
      uuidContainerMeasure
    );
    this.loading = false;
    if (!response.error) {
      this.availableContainers = response.content.map(
        (i) => new ContainerModel(i)
      );
      return this.availableContainers;
    }
  }
}

export default LockerStore;
