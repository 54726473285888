// src/stores/WebSocketStore.js
import { makeAutoObservable } from 'mobx';

class WebSocketStore {
    connections = {};
    retryDelays = {}; // armazena os delays de retentativa por URL

    constructor() {
        makeAutoObservable(this);
    }

    connect(url, onConnect, onMessage, onClose) {
        if (!this.connections[url]) {
            const socket = new WebSocket(url);

            socket.onopen = () => {
                console.log('Connected to WebSocket:', url);
                this.retryDelays[url] = 5000;
                onConnect(socket);
            };

            socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                onMessage(data);
            };

            socket.onclose = (event) => {
                console.error('WebSocket closed unexpectedly:', url);
                delete this.connections[url];
                if (onClose) onClose(event);
                this.scheduleReconnect(url, onConnect, onMessage, onClose);
            };

            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                socket.close(); // fecha a conexão em caso de erro
            };

            this.connections[url] = socket;
        }
    }

    scheduleReconnect(url, onConnect, onMessage, onClose) {
        if (!this.retryDelays[url]) {
            this.retryDelays[url] = 1000; // delay inicial de 1 segundo
        }

        const delay = this.retryDelays[url];
        console.log(`Attempting to reconnect in ${delay / 1000} seconds...`);

        setTimeout(() => {
            this.connect(url, onConnect, onMessage, onClose);
            this.retryDelays[url] = Math.min(this.retryDelays[url] * 2, 30000); // duplica o delay com um máximo de 30 segundos
        }, delay);
    }

    disconnect(url) {
        if (this.connections[url]) {
            this.connections[url].close();
            delete this.connections[url];
        }
        if (this.retryDelays[url]) {
            delete this.retryDelays[url]; // remove o delay de retentativa ao desconectar
        }
    }
}

export default new WebSocketStore();
