import BaseModel from "./BaseModel";

import AddressModel from "./AddressModel";

class OrganizationModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;
    this.name = data.name;
    this.fullName = data.fullName;
    this.address = new AddressModel(data?.address);
    this.parent = data?.parent;
    this.level = data?.level;
    this.children =
      data.children?.length > 0
        ? data.children.map((child) => new OrganizationModel(child))
        : [];
  }
}

export default OrganizationModel;
