import BaseAPI from "./BaseAPI";
import { URLS } from "./Endpoints";

class FlowAPI {
  /**Retorno generico de excessões */
  static _exception(e, message) {
    console.log(e);
    return { error: message };
  }

  /**
   * Request que busca locker por uuid
   * @param  {string} uuid - uuid do usuário
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.FLOW}/${uuid}`);
      if (response.status === 200) return response.data;
      return { error: "Erro ao buscar fluxo." };
    } catch (e) {
      return this._exception(e, "Falha ao buscar fluxo.");
    }
  }
}

export default FlowAPI;
