import BaseAPI from "./BaseAPI";
import { GenericAPI } from "./GenericAPI";

import { URLS } from "./Endpoints";

class OrganizationAPI extends GenericAPI {
  /**
   * Request que busca organização por uuid
   * @param  {string} uuid - uuid do organização
   */
  static async get(uuid) {
    try {
      const response = await BaseAPI.get(`${URLS.ORGANIZATION}/${uuid}`);
      if (response.status === 200) return response.data;
      return this._exception(response, "Erro ao buscar organização.");
    } catch (e) {
      return this._exception(e, "Falha ao buscar organização.");
    }
  }

  /**
   * Request que lista as organizacoes filhas que sao final node
   * @param  {string} uuid - uuid do organização
   */
  static async getOrganizationFinalNodes(uuid) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORGANIZATION}/${uuid}/final-nodes`
      );
      if (response.status === 200) return response.data;
      return this._exception(response, `Esta organização não tem organizações filhas.`);
    } catch (e) {
      return this._exception(e, `Esta organização não tem organizações filhas`);
    }
  }

  /**
   * Request que busca organização final node por uuid de
   * organização pai e nome
   * @param  {string} uuid - uuid do organização
   */
  static async searchFinalNodeByName(uuid, name) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORGANIZATION}/${uuid}/search-final-node-by-name/${name}`
      );
      if (response.status === 200) return response.data;
      return this._exception(response, `Não foi possivel encontrar uma organização com o nome: ${name}.`);
    } catch (e) {
      return this._exception(
        e,
        `Não foi possivel encontrar uma organização com o nome: ${name}.`
      );
    }
  }

  /**
   * Request que lista customers de uma organização
   * @param  {string} uuid - uuid do organização
   */
  static async getOrganizationCustomers(uuid, params) {
    try {
      const response = await BaseAPI.get(
        `${URLS.ORGANIZATION}/${uuid}/customers`,
        params
      );
      if (response.status === 200) return response.data;
      return this._exception(response, `Esta organização não tem clientes.`);
    } catch (e) {
      return this._exception(e, `Esta organização não tem clientes`);
    }
  }
}

export default OrganizationAPI;
