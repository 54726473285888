import LocalAPI from "../services/LocalAPI";
import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";

import { extendObservable } from "mobx";



// Store to communicate with the local API..
export default class DoorsStore {
  interval = null;

  constructor() {
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      container: null,
    });
  }

  setSelectedContainer(container) {
    this.container = container;
  }

  reset() {
    this.container = null;
    this.setIntervalOff();
  }

  // check if is current door with open status.
  async checkOpenDoor(test = false) {
    if (!this.container) return;
    try {
      const { result, resultData } = await LocalAPI.checkOpenDoor(
        this.container.serialName,
        this.container.serialPort.name,
        test
      );
      if (!result) {
        this.container = null;
        this.setIntervalOff();
      }
      if (test && this.container) {
        this.toastHelper.notify(
          STATUS_HELPER.SUCCESS,
          `Porta Fisica: ${this.container.name} - Porta Logica: ${this.container.serialName} \n Placa: ${this.container.serialPort.name}\n\n` + resultData,
          2000
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  // commando to open a door (num).
  async openDoorCommand(test = false) {
    try {
      await LocalAPI.openDoorCommand(
        this.container.serialName,
        this.container.serialPort.name,
        test
      );
      this.setIntervalOn(test);
      return true;
    } catch (err) {
      console.log("err", err);
    }
    return false;
  }

  async emergencyOpenDoor(serialPortName, serialName) {
    await LocalAPI.openDoorCommand(
      serialName,
      serialPortName
    );
  }

  // Interval Turn ON to ckechOpenDoor
  setIntervalOn(test = false) {
    if (this.interval) {
      this.setIntervalOff();
    }
    this.interval = setInterval(() => this.checkOpenDoor(test), 1000);
  }

  // Interval Turn OFF to ckechOpenDoor
  setIntervalOff() {
    if (this.interval) clearInterval(this.interval);
    this.interval = null;
  }

  // Delay method
  delay(time = 1000) {
    return new Promise(resolve => setTimeout(resolve, time));
  }
}
