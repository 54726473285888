import { runInAction, extendObservable } from "mobx";

import OrganizationModel from "../models/OrganizationModel";
import UserModel from "../models/UserModel";
import OrganizationAPI from "../services/OrganizationAPI";
import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";

/**Valores inicias de variaveis observadas */
const initValues = {
  selectedCustomer: undefined,
  selectedOrganization: undefined,
  organization: undefined,
  organizations: [],
  organizationsListSelect: undefined,
  finalNodeOrganizations: [],
  customers: [],
  loading: false,
  empty: false,
  page: 1,
  totalElements: undefined,
  totalPages: undefined,
  filter: undefined,
};
class OrganizationStore {
  // totalPages = 0;
  //page = 0;
  //size = 20;
  /**Constructor */
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Reset values */
  reset() {
    this.selectedCustomer = undefined;
    this.selectedOrganization = undefined;
    this.organization = undefined;
    this.organizations = [];
    this.customers = [];
    this.finalNodeOrganizations = [];
    this.setLoading(false);
    this.empty = false;
    this.page = 1;
    this.totalElements = undefined;
    this.totalPages = undefined;
    this.filter = undefined;
  }

  /**Cria nova organização com dados padrão */
  emptyOrganization() {
    this.organization = new OrganizationModel({});
  }

  selectCustomer = (customer) => {
    runInAction(() => {
      this.selectedCustomer = customer;
    })
  }

  selectOrganization(organization) {
    runInAction(() => {
      this.selectedOrganization = organization
    })
  }

  async setLoading(value) {
    runInAction(() => {
      this.loading = value;
    })
  }

  setOrganization(organization) {
    runInAction(() => {
      this.organization = organization;
    })
  }

  /**Busca organização por uuid */
  async get(uuid) {
    // this.organization = [];
    this.setLoading(true);
    const response = await OrganizationAPI.get(uuid);
    if (!response.error) {
      this.setOrganization(new OrganizationModel(response));
    }
    this.setLoading(false);
  }

  /**Busca organizacoes final nodes por uuid de organização pai*/
  async getOrganizationFinalNodes(uuid) {
    this.setLoading(true);
    const response = await OrganizationAPI.getOrganizationFinalNodes(uuid);
    this.setLoading(false);
    if (!response.error) {
      this.finalNodeOrganizations = response.content.map(
        (item) => new OrganizationModel(item)
      );
      return this.finalNodeOrganizations;
    }
  }

  /**Busca organização final node por nome e por uuid de organização pai*/
  async searchFinalNodeByName(uuid, name) {
    this.setLoading(true);
    const response = await OrganizationAPI.searchFinalNodeByName(uuid, name);
    this.setLoading(false);
    if (!response.error) {
      this.setOrganization(new OrganizationModel(response));
      return this.organization;
    }
  }

  /**Busca customers de organização uuid */
  async getOrganizationCustomers(uuid) {
    this.setLoading(true);
    const response = await OrganizationAPI.getOrganizationCustomers(uuid, {
      ordering: "full_name",
    });
    this.setLoading(false);
    if (!response.error) {
      this.customers = response.map((item) => new UserModel(item));
      return this.customers;
    }
  }
}

export default OrganizationStore;
