import BaseModel from "./BaseModel";
import OrganizationModel from "./OrganizationModel";
import ModuleModel from "./ModuleModel";

class LockerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data.name;
    this.daysToReverse = data.daysToReverse;
    this.qrCode = data.qrCode;
    this.organization = new OrganizationModel(data?.organization);
    this.modules =
      data?.modules?.length > 0
        ? data?.modules.map((i) => new ModuleModel(i))
        : [];
  }

  totalWidth() {
    let summedWidth = 0;

    for (let index = 0; index < this.modules.length; index++) {
      summedWidth = summedWidth + this.modules[index].width;
    }

    console.log(summedWidth);
    return summedWidth;
  }
}

export default LockerModel;
