import React from "react";
import Router from "./pages/Router";
import rootStore from "./stores/RootStore";

import { Provider } from "mobx-react";

import { initializeStores } from "./stores/RootStore";


// Rendering Router component and sending rootStore as props.
export default () => {
  const mobxStore = initializeStores().mobxStores;

  // setInterval(() => {
  //   const timestamp = formatDateString(new Date());

  //   const cpuUsage = window.performance.now() / 1000;
  //   const ramTotal = window.performance.memory.jsHeapSizeLimit / (1024 * 1024);
  //   const ramTotalJS =
  //     window.performance.memory.totalJSHeapSize / (1024 * 1024);
  //   const ramUsedJS = window.performance.memory.usedJSHeapSize / (1024 * 1024);

  //   console.log(`${timestamp} - CPU Usage: ${cpuUsage}`);
  //   console.log(`${timestamp} - RAM Total: ${ramTotal}`);
  //   console.log(`${timestamp} - RAM Total JS: ${ramTotalJS}`);
  //   console.log(`${timestamp} - RAM Used JS: ${ramUsedJS}`);
  //   console.log(`=============================================`);
  // }, 3000);

  // process.on("uncaughtException", function (err) {
  //   console.log("uncaughtException ", err);
  // });
  return (
    <Provider {...mobxStore}>
      <div className="App">
        <Router {...rootStore} />
      </div>
    </Provider>
  );
};
