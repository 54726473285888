import { extendObservable } from "mobx";

import CompanyFeaturesModel from "../models/CompanyFeaturesModel";
import CompanyAPI from "../services/CompanyAPI";

import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";

/**Valores inicias de variaveis observadas */
const initValues = {
  companyFeatures: undefined,
  loading: false,
};
class CompanyStore {
  /**Constructor */
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Reset values */
  reset() {
    this.companyFeatures = undefined;
    this.loading = false;
  }

  /**Cria nova categoria com dados padrão */
  emptyCompany() {
    this.companyFeatures = new CompanyFeaturesModel({});
  }

  /**Busca configurações de empresa */
  async getFeatures() {
    this.loading = true;
    const response = await CompanyAPI.getFeatures();
    if (!response.error) {
      this.companyFeatures = new CompanyFeaturesModel(response);
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }
}

export default CompanyStore;
