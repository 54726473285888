import BaseModel from "./BaseModel";

class PageModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data?.name;
    this.path = data?.path;
    this.params = data?.params;
  }

  mountPageToPushUrl(dict = {}) {
    let pageToPush = "/" + this.path;

    // oputput = /allocate-container/type/uuidType/measure/uuidMeasure
    if (this.params)
      Object.entries(this.params).forEach(([key, value]) => {
        pageToPush = `${pageToPush}/${key}/${dict[value]}`;
      });

    return pageToPush;
  }
}

export default PageModel;
