import React from "react";

/**
 * @Name: BodyComponent
 * @Data: 2023
 * @Desc: Cabecalho do Sistema
 * @props:
 *  children: conteudo a ser apresentado no corpo da page
 */

const BodyComponent = ({ children }) => {
  // Recebe as propriedades

  return (
    <React.Fragment>
      <div className="BodyComponent">{children}</div>
    </React.Fragment>
  );
};

export default BodyComponent;
