import LockerStore from "./LockerStore";
import DoorsStore from "./DoorsStore";
import CompanyStore from "./CompanyStore";
import OrganizationStore from "./OrganizationStore";
import FlowStore from "./FlowStore";
import UserStore from "./UserStore";
import AllocationStore from "./AllocationStore";

class RootStore {
  _mobxStores = null;

  constructor(
    serializedStores = {
      lockerStore: {},
      doorsStore: {},
      companyStore: {},
      organizationStore: {},
      flowStore: {},
      userStore: {},
      allocationStore: {},
      localStorage: {},
    }
  ) {
    if (this._mobxStores === null) {
      this.lockerStore = new LockerStore();
      this.doorsStore = new DoorsStore();
      this.companyStore = new CompanyStore();
      this.organizationStore = new OrganizationStore();
      this.flowStore = new FlowStore();
      this.userStore = new UserStore();
      this.allocationStore = new AllocationStore();
      this.localStorage = localStorage;
    }
  }

  get mobxStores() {
    return (this._mobxStores = {
      lockerStore: this.lockerStore,
      doorsStore: this.doorsStore,
      companyStore: this.companyStore,
      organizationStore: this.organizationStore,
      flowStore: this.flowStore,
      userStore: this.userStore,
      allocationStore: this.allocationStore,
      localStorage: this.localStorage,
    });
  }
}

let store = null;

export function initializeStores(props) {
  if (store === null) {
    store = new RootStore(props);
  }
  return store;
}

export default RootStore;
