import BaseModel from "./BaseModel";
import ContainerMeasureModel from "./ContainerMeasureModel";
import ContainerTypeModel from "./ContainerTypeModel";

class ContainerModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    if (!data) return;

    this.name = data.name;
    this.serialName = data.serialName;
    this.serialPort = data.serialPort;
    this.ordering = data?.ordering;
    this.isAvailable = data?.isAvailable;

    this.module = data?.module;

    this.containerMeasure = new ContainerMeasureModel(data?.containerMeasure);
    this.containerType = new ContainerTypeModel(data?.containerType);
  }
}

export default ContainerModel;
