import React from "react";

import logoStoom from "../../assets/logo-stoom.png";
/**
 * @Name: FooterComponent
 * @Data: 2023
 * @Desc: Footer do Sistema
 */

const FooterComponent = () => {
  const currentYear = new Date().getFullYear();

  return (
    <React.Fragment>
      <footer className="FooterComponent">
        Powered by <img alt="stoom logo" src={logoStoom} height={30} /> ©{currentYear}
      </footer>
    </React.Fragment>
  );
};

export default FooterComponent;
