import { extendObservable } from "mobx";

import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";

import StorageUtil, { KEYS } from "../helpers/utils/StorageUtil";
import FlowModel from "../models/FlowModel";
import FlowAPI from "../services/FlowAPI";

const initValues = {
  flow: undefined,
  index: 0,
  loading: false,
};

class FlowStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, initValues);
  }

  reset() {
    this.flow = undefined;
    this.index = 0;
    this.loading = false;
  }

  /**Verifica se a credencial do usuário é de superadmin */
  get isStaff() {
    const currentAuth = StorageUtil.getItem(KEYS.AUTH_KEY);
    return currentAuth === "SUPERADMIN" || currentAuth === "STOOM";
  }

  emptyFlow() {
    this.flow = new FlowModel({});
  }

  getActualPage() {
    return this.flow.flowpages[this.index];
  }

  getPreviousPage() {
    if (this.index > 0) return this.flow.flowpages[this.index - 1];
  }

  decrementPage() {
    this.index = this.index - 1;
  }

  getNextPage() {
    if (this.index + 1 < this.flow?.flowpages.length) {
      return this.flow.flowpages[this.index + 1];
    }
  }

  incrementPage() {
    this.index = this.index + 1;
  }

  /**
   * Busca todas as informações de um locker
   * @param  {string} uuid
   */
  async get(uuid) {
    this.loading = true;

    const response = await FlowAPI.get(uuid);
    this.loading = false;
    if (!response.error) {
      this.flow = new FlowModel(response);
      return this.flow;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }
}

export default FlowStore;
