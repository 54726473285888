/* eslint-disable */

/*
    Config: Configurações para teste local, stating e  Prod.
    Por fazer conexão com backend e com o Locker (local) é necessário dois ambientes para cada configuração;
    As configurações:

    prod: Backend Prod e Locker Prod. Test is false.

    staging: Backend STAGING e Locker Prod.
    stagingLocal: Backend STAGING e Mock LOCAL do Locker.

    local: Backend LOCAL e Mock LOCAL do Locker.
*/

const prod = { test: false };

// SERVER: STAGING
// LOCKER: PROD.
const staging = { test: false };

const config = {
  ...prod,
  // url: "https://lass-api.stoom.com.br",
  url: "https://lass-api.dev.stoomlab.com.br",
  // url: "http://localhost:8000",
  // urllocker: "http://localhost:8005",
  urllocker: "https://lass-laurenti-api.dev.stoomlab.com.br",
  // urlsocket: "ws://lass-api.prd.stoomlab.com.br/ws",
  urlsocket: "wss://lass-api.dev.stoomlab.com.br/ws",
  // urlsocket: "ws://localhost:8000/ws",
};

if (process.env.REACT_APP_API_URL === "https://lass-api.stg.stoomlab.com.br") {
  console.log("AMBIENTE DE STAGING");
  config.url = "https://lass-api.stg.stoomlab.com.br";
  config.urlsocket = "ws://lass-api.stg.stoomlab.com.br/ws/command/";
}

if (process.env.REACT_APP_API_URL === "https://lass-api.dev.stoomlab.com.br") {
  console.log("AMBIENTE DE DEV");
  config.url = "https://lass-api.dev.stoomlab.com.br";
  config.urlsocket = "ws://lass-api.dev.stoomlab.com.br/ws/command/";
}

if (process.env.REACT_APP_API_URL === "http://localhost:8000") {
  console.log("AMBIENTE DE DESENVOLVIMENTO (LOCAL) ");
  config.url = "http://localhost:8000";
  config.urlsocket = "ws://localhost:8000/ws/command/";
}

export default config;
