import { extendObservable, runInAction } from "mobx";

import AllocationModel from "../models/AllocationModel";
import AllocationAPI from "../services/AllocationAPI";

import ToastHelper, { STATUS_HELPER } from "../helpers/ToastHelper";
import { KEYS } from "../helpers/utils/StorageUtil";

/**Valores inicias de variaveis observadas */
const initValues = {
  allocation: undefined,
  reverses: [],
  loading: false,
};
class AllocationStore {
  /**Constructor */
  constructor(rootStore) {
    this.rootStore = rootStore;
    extendObservable(this, initValues);
    this.toastHelper = new ToastHelper();
  }

  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Reset values */
  reset() {
    this.allocation = undefined;
    this.loading = false;
    this.reverses = [];
  }

  setAllocation(allocation) {
    runInAction(() => {
      this.allocation = allocation;
    })
  }

  async getAllocationByUuid(uuid) {
    this.loading = true;
    const response = await AllocationAPI.getAllocationByUuid(uuid);
    if (!response.error) {
      this.setAllocation(new AllocationModel(response));
      return this.allocation;
    }
    this.loading = false;
  }

  async getAllocationByCodeAndLockerUuid(code) {
    this.loading = true;
    const response = await AllocationAPI.getAllocationByCodeAndLockerUuid(
      code,
      localStorage.getItem(KEYS.LOCKER_UUID)
    );
    if (!response.error) {
      try {
        this.setAllocation(new AllocationModel(response));
        return this.allocation;
      } catch (error) {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          "Pedido com inconsistência no cadastro"
        );
        return;
      }
    }
    this.loading = false;
  }

  async getAllocationByExternalCodeAndLockerUuid(code) {
    this.loading = true;
    const response =
      await AllocationAPI.getAllocationByExternalCodeAndLockerUuid(
        code,
        localStorage.getItem(KEYS.LOCKER_UUID)
      );
    if (!response.error) {
      try {
        this.setAllocation(new AllocationModel(response));
        return this.allocation;
      } catch (error) {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          "Pedido com inconsistência no cadastro"
        );
        return;
      }
    }
    this.loading = false;
  }

  async getLockerReverseList() {
    this.loading = true;
    const response = await AllocationAPI.getLockerReverseList(
      localStorage.getItem(KEYS.LOCKER_UUID)
    );
    this.loading = false;

    if (!response.error) {
      this.reverses = response.content.map((i) => new AllocationModel(i));
      return this.reverses;
    }
  }

  /**Busca configurações de empresa */
  async create(data) {
    this.loading = true;
    delete data['containerAllocations']
    const response = await AllocationAPI.create(data);
    if (!response.error) {
      this.setAllocation(new AllocationModel(response));
      return this.allocation;
    }
    this.loading = false;
  }

  /**Busca configurações de empresa */
  async allocate(data) {
    this.loading = true;
    console.log(this.allocation)
    const response = await AllocationAPI.allocate(this.allocation.uuid, data);
    if (!response.error) {
      this.setAllocation(new AllocationModel(response));
      return this.allocation;
    }
    this.loading = false;
  }

  /**Busca configurações de empresa */
  async allocationFinished() {
    this.loading = true;
    const response = await AllocationAPI.allocationFinished(
      this.allocation.uuid,
      this.allocation
    );
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    }
    this.loading = false;
  }

  /**Busca configurações de empresa */
  async deallocate(data) {
    this.loading = true;
    const response = await AllocationAPI.deallocate(this.allocation.uuid, data);
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    }
    this.loading = false;
  }

  /**Busca configurações de empresa */
  async reverse(data) {
    this.loading = true;
    const response = await AllocationAPI.reverse(this.allocation.uuid, data);
    if (!response.error) {
      this.allocation = new AllocationModel(response);
      return this.allocation;
    }
    this.loading = false;
  }

  async allocationPhoto(uuid, photo) {
    this.loading = true;

    const data = new FormData();
    const file = new File([photo], uuid + ".jpg", { type: "image/jpeg" });
    data.append("photo", file);

    const response = await AllocationAPI.allocationPhoto(uuid, data);
    if (!response.error) {
      return true;
    }
    this.loading = false;
  }
}


export default AllocationStore;
