import React from "react";
import logoStoom from "../../assets/logo-stoom.png";

/**
 * @Name: CompanyLogoComponent
 * @Data: 2023
 * @Desc: Cabecalho do Sistema
 */

const CompanyLogoComponent = ({ src }) => {
  // Recebe as propriedades

  return (
    <React.Fragment>
      <div className="CompanyLogoComponent">
        <img alt="" src={src ?? logoStoom} height={60} />
      </div>
    </React.Fragment>
  );
};

export default CompanyLogoComponent;
