import BaseModel from "./BaseModel";
import OrganizationModel from "./OrganizationModel";

/**Modelo usuário */
class UserModel extends BaseModel {
  constructor(data = {}) {
    super(data);
    this.email = data.email;
    this.phone = data.phone;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.fullName = `${this.firstName} ${this.lastName}`;
    this.password = data.password;
    this.organization = new OrganizationModel(data.organization);
    this.cardFullName = data.firstName.split(" ")[0] + " " + data.lastName.split(" ")[0];
  }

  getInitials() {
    return (this.firstName[0] + this.lastName[0]).toUpperCase();
  }


}

export default UserModel;
